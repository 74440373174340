import { Grid } from "@mui/material";
import React from "react";
import chatBox from "../../images/chatBox.png";
import { styles } from "./styles";

const ChatBox = () => {
  return (
    <>
      <Grid item xs={1} mt={64} ml={1} style={styles.chatBoxImg}>
        <img src={chatBox} alt="chatBox" width="60%" />
      </Grid>
    </>
  );
};

export default ChatBox;
