import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ChatBox from "../HomePage/chatBox";
import { useDispatch } from "react-redux";
import { loginActions } from "../actions/loginActions";
import { HomePage } from "../HomePage/homePage";
import { styles } from "./styles";

export const LoginPage = () => {
  const [loginDetails, setLoginDetails] = useState({
    customerId: "",
    customerType: "",
  });
  const [currentPage, setCurrentPage] = useState("login");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const handleLogin = () => {
    dispatch(loginActions.getLoginDetails(loginDetails));
    setCurrentPage("home");
  };

  useEffect(() => {
    try {
      if (
        loginDetails?.customerId !== "" &&
        loginDetails?.customerType !== ""
      ) {
        setIsLoggedIn(true);
        dispatch(loginActions.getLoginStatus(true));
      } else {
        setIsLoggedIn(false);
        dispatch(loginActions.getLoginStatus(false));
      }
    } catch (error) {
      console.error("Error dispatching login action:", error);
    }
  }, [loginDetails, dispatch]);

  return (
    <>
      {currentPage === "login" ? (
        <Grid container mt={2}>
          <ChatBox />
          <Grid item xs={10} mt={8} style={styles.flexColumnCenter}>
            <Typography variant="h5">
              <b>Account Login</b>
            </Typography>
            <TextField
              type="text"
              name="customerId"
              placeholder="Login ID"
              size="small"
              value={loginDetails.customerId}
              onChange={handleInputChange}
              sx={{ m: 3, backgroundColor: "#fff" }}
            />
            <TextField
              type="password"
              placeholder="Password"
              size="small"
              sx={{ backgroundColor: "#fff" }}
            />
            <FormControl
              size="small"
              sx={{ m: 3, width: 220, backgroundColor: "#fff" }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="customerType"
                value={loginDetails.customerType}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleInputChange}
              >
                <MenuItem value={""}>Select Customer Type</MenuItem>
                <MenuItem value={"Retail"}>Retail</MenuItem>
                <MenuItem value={"Corporate"}>Commercial</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              size="large"
              style={styles.loginBtn}
              onClick={handleLogin}
            >
              Login
            </Button>
            <Box sx={{ display: "flex" }}>
              <a href="#" style={styles.links}>
                Forgot Password
              </a>
              <a href="#" style={styles.links}>
                Enroll
              </a>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <HomePage />
      )}
    </>
  );
};
