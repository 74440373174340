import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import CurrentProducts from "../currentProducts.js";
import ChatBox from "../../HomePage/chatBox.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoginToServices } from "../../LoginPage/loginToServices.js";
import { styles } from "../styles.js";

const AccountServices = () => {
  const [jurisdiction, setJurisdiction] = useState("");
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledRows, setDisabledRows] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [openRows, setOpenRows] = useState({});
  const [openAvailableRows, setOpenAvailableRows] = useState({});
  const [openRowConfiguration, setOpenRowConfiguration] = useState({});
  const [openRowOverview, setOpenRowOverview] = useState({});
  const [apiAdditionalFields, setApiAdditionalFields] = useState();
  const [submittedResponse, setSubmittedResponse] = useState();
  const [backgroundColorResponse, setBackgroundColorResponse] =
    useState("success");
  const [open, setOpen] = React.useState(false);
  const [emailError, setEmailError] = useState(false);
  const [closePage, setClosePage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(null);

  let isServiceLogin =
    useSelector((state) => state?.loginDetails?.serviceLogStatus) || false;

  let isLoggedIn =
    useSelector((state) => state?.loginDetails?.loginStatus) || false;

  const loginDetails =
    useSelector((state) => state?.loginDetails?.loginDetails) || {};

  const category = "Account Opening Solutions";

  //Validating loggedin customer id
  useEffect(() => {
    async function fetchCustomerId() {
      try {
        const res = await fetch(
          `https://cpao.evonsys.com.au/prweb/api/v1/data/D_Customer?CustomerID=${loginDetails.customerId}`
        );

        if (res.status === 200) {
          setIsValidCustomer(true); // Valid customer login
        } else {
          setIsValidCustomer(false); // Invalid customer login
        }
      } catch (error) {
        console.error("Error fetching customer ID:", error);
        setIsValidCustomer(false); // Set to false in case of an error
      }
    }

    fetchCustomerId();
  }, [loginDetails.customerId]);

  //Fetching available jurisdictions through API
  useEffect(() => {
    async function fetchAvailableJurisdictions() {
      const res = await fetch(
        `https://cpao.evonsys.com.au/prweb/api/v1/data/D_FetchJurisdictionData`
      );

      const jsonData = await res.json();
      // console.log(jsonData);
      const data = jsonData.pxResults.map((i) => i.EntityValue1);
      // console.log(data);
      setJurisdictionOptions(data);
    }
    fetchAvailableJurisdictions();
  }, []);

  //Fetching available products based on jurisdiction & product category through API
  useEffect(() => {
    async function fetchAvailableProducts() {
      if (jurisdiction !== "" && isValidCustomer) {
        try {
          const res = await fetch(
            `https://cpao.evonsys.com.au/prweb/api/v1/data/D_FetchProdsByCatAndLoc?Location=${jurisdiction}&CustomerID=${loginDetails.customerId}&ProdCategory=${category}`
          );

          const jsonData = await res.json();
          const data = jsonData.pxResults;
          setAvailableProducts(data);
        } catch (error) {
          console.error("Error fetching available products:", error);
        }
      }
    }
    fetchAvailableProducts();
  }, [isValidCustomer, jurisdiction, loginDetails.customerId]);

  //Fetching additional fields based on customer type through API
  async function fetchAdditionalFields(row) {
    const response = await fetch(
      `https://cpao.evonsys.com.au/prweb/api/v1/data/D_RequirementList?ProcessType=OnboardNewProduct&BusinessSegment=${loginDetails.customerType}&ProductType=${row.ProductType}`
    );
    const jsonData = await response.json();
    const additionalData = jsonData.pxResults;
    setApiAdditionalFields(additionalData);
  }

  //Handle to add selected rows to selected products table
  const addSelectedRow = (row) => {
    setSelectedRows([...selectedRows, row]);
    setDisabledRows([...disabledRows, row.ProductCode]);
    setOpenRows({ ...openRows, [row.ProductCode]: false });
    fetchAdditionalFields(row);
    // Initialize additional fields for the selected row
    setAdditionalFields([
      ...additionalFields,
      { ProductCode: row.ProductCode, fields: [] },
    ]);
  };

  //Hanlde to delete selected row
  const handleDeleteRow = (row) => {
    const updatedSelectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.ProductCode !== row.ProductCode
    );
    setSelectedRows(updatedSelectedRows);
    setDisabledRows(disabledRows.filter((code) => code !== row.ProductCode));
    // Remove additional fields associated with the deleted row
    setAdditionalFields(
      additionalFields.filter((field) => field.ProductCode !== row.ProductCode)
    );
  };

  //Toggle to expand and close the selected row
  const toggleRowOpenState = (productCode) => {
    setOpenRows({
      ...openRows,
      [productCode]: !openRows[productCode],
    });
  };

  //Toggle to expand and close the available row
  const toggleAvailableRowOpenState = (productCode) => {
    setOpenAvailableRows({
      ...openAvailableRows,
      [productCode]: !openAvailableRows[productCode],
    });
  };

  //Toggle to expand and close the configuration to add additional fields
  const toggleAddFieldState = (productCode) => {
    setOpenRowConfiguration({
      ...openRowConfiguration,
      [productCode]: !openRowConfiguration[productCode],
    });
  };

  //Toggle to expand and close the Overview
  const toggleOverview = (productCode) => {
    setOpenRowOverview({
      ...openRowOverview,
      [productCode]: !openRowOverview[productCode],
    });
  };

  //Getting additional TextField value based on type of the field
  const getAdditionalTextFieldValue = (field, apiField) => {
    const foundField = field.fields.find(
      (i) =>
        i.EntityName === apiField.EntityName &&
        i.EntityType === apiField.EntityType
    );
    if (foundField) {
      switch (apiField.EntityType.toLowerCase()) {
        case "integer":
          return foundField.EntityIntegerValue || "";
        case "email":
          return foundField.EntityEmailValue || "";
        case "date":
          return foundField.EntityDateValue || "";
        case "decimal":
          return foundField.EntityDecimalValue || "";
        default:
          return foundField.EntityTextValue || "";
      }
    }
    return "";
  };

  //Setting initial additional fields based on the apiAdditionalFields from api
  useEffect(() => {
    if (
      apiAdditionalFields &&
      apiAdditionalFields.length > 0 &&
      selectedRows &&
      selectedRows.length > 0
    ) {
      const initialAdditionalFields = selectedRows.map((row) => {
        const fields = apiAdditionalFields.map((field) => ({
          EntityDatetimeValue: "",
          EntityDateValue: "",
          EntityDecimalValue: "",
          EntityEmailValue: "",
          EntityIntegerValue: "",
          EntityLabel: field.EntityLabel,
          EntityName: field.EntityName,
          EntityTextValue: "",
          EntityType: field.EntityType,
        }));
        return { ProductCode: row.ProductCode, fields };
      });
      setAdditionalFields(initialAdditionalFields);
    }
  }, [apiAdditionalFields, selectedRows]);

  //Setting the Specific type field name
  const getTypeSpecificValueFieldName = (type) => {
    switch (type) {
      case "number":
        return "EntityIntegerValue";
      case "email":
        return "EntityEmailValue";
      case "date":
        return "EntityDateValue";
      case "decimal":
        return "EntityDecimalValue";
      default:
        return "EntityTextValue";
    }
  };

  // Email validation function
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle input change in dynamic fields
  const handleInputChange = (e, productCode, fieldName) => {
    const { type, value } = e.target;
    let errorMessage = null;

    // Validate email input
    if (type === "email" && !validateEmail(value)) {
      errorMessage = "Please enter a valid email address.";
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    // Set field error message
    setFieldError(productCode, fieldName, errorMessage);

    // Update additional fields
    setAdditionalFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.ProductCode === productCode) {
          const updatedFields = field.fields.map((f) => {
            if (f.EntityName === fieldName) {
              return {
                ...f,
                [getTypeSpecificValueFieldName(type)]: value,
                error: errorMessage, // Set error message
              };
            }
            return f;
          });
          return { ...field, fields: updatedFields };
        }
        return field;
      });
    });
  };

  // console.log(emailError);

  // Function to set an error message for a specific field
  const setFieldError = (productCode, fieldName, errorMessage) => {
    setAdditionalFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.ProductCode === productCode) {
          const updatedFields = field.fields.map((f) => {
            if (f.EntityName === fieldName) {
              return {
                ...f,
                error: errorMessage, // Add or remove error message property
              };
            }
            return f;
          });
          return { ...field, fields: updatedFields };
        }
        return field;
      });
    });
  };

  //function to add updated additionalfields to selectedRows & returns array to submit the request
  const mapAdditionalFieldsToRows = () => {
    const mappedRows = selectedRows.map((row) => {
      const additionalField = additionalFields.find(
        (field) => field.ProductCode === row.ProductCode
      );

      if (additionalField) {
        // const nonEmptyFields = additionalField.fields.filter((field) => {
        //   switch (field.EntityType.toLowerCase()) {
        //     case "integer":
        //       return field.EntityIntegerValue !== "";
        //     case "email":
        //       return field.EntityEmailValue !== "";
        //     case "date":
        //       return field.EntityDateValue !== "";
        //     case "decimal":
        //       return field.EntityDecimalValue !== "";
        //     default:
        //       return field.EntityTextValue !== "";
        //   }
        // });

        //   if (nonEmptyFields.length > 0) {
        //     return {
        //       ...row,
        //       AdditionalFields: nonEmptyFields,
        //     };
        //   }
        // }

        return {
          ...row,
          AdditionalFields: additionalField.fields,
        };
      }

      return row;
    });
    // console.log("mappedRows", mappedRows);
    return mappedRows;
  };

  //Handle close for snackbar (toast message)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setClosePage(true);
    setOpen(false);
  };

  //Handle to submit the final request products
  const handleSubmitReview = async () => {
    const updatedRows = mapAdditionalFieldsToRows();
    setOpen(true);
    setSubmitDisabled(true);
    let data = {};

    //Assigning request data to post to API
    if (updatedRows.length !== 0)
      data = {
        caseTypeID: "ES-CPAO-Work-NewProduct",
        content: {
          CustomerID: loginDetails.customerId,
          CustomerType:
            loginDetails.customerType === "Corporate"
              ? "Commercial"
              : loginDetails.customerType,
          Source: "Web",
          pyWorkParty: {
            Customer: {
              pxObjClass: "Data-Party",
              pxSubscript: "Customer",
              pyWorkPartyUri: "mahesh.koul@evonsys.com",
              ProductList: updatedRows,
              pzIndexes: {
                PartyURI: "0",
              },
            },
          },
        },
      };

    //Posting the request data to API
    try {
      const response = await fetch(
        "https://cpao.evonsys.com.au/prweb/api/v1/cases",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const data = JSON.stringify(responseData);
        console.log("Submission successful", responseData);
        setSubmittedResponse(
          `Product ${responseData.ID} is created sucessfully`
        );
        setBackgroundColorResponse("success");
        isServiceLogin && !isLoggedIn
          ? setTimeout(() => (window.location.href = "/"), 8000)
          : setTimeout(() => setClosePage(true), 8000);
      } else {
        console.error("Product Submission failed");
        setSubmittedResponse("Product Submission failed");
        setBackgroundColorResponse("error");
        isServiceLogin && !isLoggedIn
          ? setTimeout(() => (window.location.href = "/"), 8000)
          : setTimeout(() => setClosePage(true), 8000);
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      setSubmittedResponse(error);
      setBackgroundColorResponse("error");
    }
  };

  return (
    <>
      <Grid container mt={2} style={styles.flexAround}>
        <ChatBox />
        <Grid item xs={10} style={styles.flexCenter}>
          {!isServiceLogin && !isLoggedIn ? (
            <LoginToServices />
          ) : (
            <Paper elevation={2} style={styles.servicesContainer}>
              <Box style={styles.flexBetweenCenter}>
                <div style={styles.headingContainer}>
                  <Avatar style={styles.avatar}>MK</Avatar>
                  <Typography variant="body1">
                    <b>Account Services - Product Selection</b>
                  </Typography>
                </div>
                <div>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <IconButton id="cancelBtn" color="error">
                      <CancelIcon fontSize="large" />
                    </IconButton>
                  </Link>
                </div>
              </Box>

              {isValidCustomer === null ? (
                <p>Validating Customer ID...</p>
              ) : isValidCustomer === false ? (
                <Alert severity="error">
                  Sorry!! CustomerID - {loginDetails.customerId} is invalid.
                  Please enter a valid Customer ID.
                </Alert>
              ) : isValidCustomer === true ? (
                <>
                  <Box mt={1} style={styles.flexAlignCenter}>
                    <Typography>
                      <b>
                        Jurisdiction<span style={{ color: "red" }}>*</span>
                      </b>
                    </Typography>

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={jurisdictionOptions}
                      value={jurisdiction}
                      onChange={(e, v) => setJurisdiction(v)}
                      sx={{ width: 200, marginLeft: 2 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Jurisdiction"
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "50px",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>

                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={styles.productsHeading}
                    >
                      <b>Available Products</b>
                    </Typography>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 1200 }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead style={styles.tableHeading}>
                          <TableRow>
                            <TableCell
                              style={styles.tableHeadingLeftCells}
                            ></TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Jurisdiction</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Category</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Name</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Type</b>
                            </TableCell>
                            <TableCell style={styles.tableHeadingRightCells}>
                              <b>Action</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {availableProducts?.length !== 0 ? (
                            <>
                              {availableProducts?.map((row) => (
                                <React.Fragment key={row.ProductCode}>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      onClick={() =>
                                        toggleAvailableRowOpenState(
                                          row.ProductCode
                                        )
                                      }
                                      sx={{ cursor: "pointer", border: "none" }}
                                    >
                                      {openAvailableRows[row.ProductCode] ? (
                                        <ArrowDropDownIcon />
                                      ) : (
                                        <ArrowRightIcon />
                                      )}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.EntityValue1}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductCategory}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductName}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductType}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      <Button
                                        key={row.ProductCode}
                                        onClick={() => addSelectedRow(row)}
                                        disabled={disabledRows.includes(
                                          row.ProductCode
                                        )}
                                      >
                                        <b>Add</b>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={styles.expandedRow}
                                      colSpan={6}
                                    >
                                      <Collapse
                                        in={openAvailableRows[row.ProductCode]}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1}>
                                          <List
                                            component="div"
                                            disablePadding
                                            sx={{ marginLeft: 8 }}
                                          >
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                              onClick={() =>
                                                toggleOverview(row.ProductCode)
                                              }
                                            >
                                              {openRowOverview[
                                                row.ProductCode
                                              ] ? (
                                                <ArrowDropDownIcon />
                                              ) : (
                                                <ArrowRightIcon />
                                              )}
                                              <ListItemText primary="Overview" />
                                            </ListItemButton>

                                            <Collapse
                                              in={
                                                openRowOverview[row.ProductCode]
                                              }
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box
                                                style={styles.expandedRow}
                                                marginLeft={8}
                                              >
                                                {row.ProductDescription}
                                              </Box>
                                            </Collapse>

                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Credit" />
                                            </ListItemButton>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Documentation" />
                                            </ListItemButton>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Other" />
                                            </ListItemButton>
                                            {/* <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Collect Configurations" />
                                            </ListItemButton> */}
                                          </List>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {
                                <TableRow>
                                  <TableCell
                                    colSpan={6}
                                    align="center"
                                    sx={{ borderBottom: "none" }}
                                  >
                                    Select Jurisdiction to get available
                                    products.
                                  </TableCell>
                                </TableRow>
                              }
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={styles.productsHeading}
                    >
                      <b>Selected Products</b>
                    </Typography>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 1200 }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead style={styles.tableHeading}>
                          <TableRow>
                            <TableCell
                              style={styles.tableHeadingLeftCells}
                            ></TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Jurisdiction</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Category</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Name</b>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <b>Product Type</b>
                            </TableCell>
                            <TableCell style={styles.tableHeadingRightCells}>
                              <b>Action</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedRows.length !== 0 ? (
                            <>
                              {selectedRows?.map((row) => (
                                <React.Fragment key={row.ProductCode}>
                                  <TableRow>
                                    <TableCell
                                      onClick={() =>
                                        toggleRowOpenState(row.ProductCode)
                                      }
                                      sx={{ cursor: "pointer", border: "none" }}
                                    >
                                      {openRows[row.ProductCode] ? (
                                        <ArrowDropDownIcon />
                                      ) : (
                                        <ArrowRightIcon />
                                      )}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.EntityValue1}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductCategory}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductName}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      {row.ProductType}
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      <Button
                                        onClick={() => handleDeleteRow(row)}
                                      >
                                        <b>Delete</b>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={styles.expandedRow}
                                      colSpan={6}
                                    >
                                      <Collapse
                                        in={openRows[row.ProductCode]}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1}>
                                          <List
                                            component="div"
                                            disablePadding
                                            sx={{ marginLeft: 8 }}
                                          >
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                              onClick={() =>
                                                toggleOverview(row.ProductCode)
                                              }
                                            >
                                              {openRowOverview[
                                                row.ProductCode
                                              ] ? (
                                                <ArrowDropDownIcon />
                                              ) : (
                                                <ArrowRightIcon />
                                              )}
                                              <ListItemText primary="Overview" />
                                            </ListItemButton>

                                            <Collapse
                                              in={
                                                openRowOverview[row.ProductCode]
                                              }
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box
                                                style={styles.expandedRow}
                                                marginLeft={8}
                                              >
                                                {row.ProductDescription}
                                              </Box>
                                            </Collapse>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Credit" />
                                            </ListItemButton>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Documentation" />
                                            </ListItemButton>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                            >
                                              <ArrowRightIcon />
                                              <ListItemText primary="Other" />
                                            </ListItemButton>
                                            <ListItemButton
                                              style={styles.expandedListBtn}
                                              onClick={() =>
                                                toggleAddFieldState(
                                                  row.ProductCode
                                                )
                                              }
                                            >
                                              {openRowConfiguration[
                                                row.ProductCode
                                              ] ? (
                                                <ArrowDropDownIcon />
                                              ) : (
                                                <ArrowRightIcon />
                                              )}
                                              <ListItemText primary="Collect Configurations" />
                                            </ListItemButton>
                                            <TableCell
                                              style={styles.expandedRow}
                                              colSpan={6}
                                            >
                                              <Collapse
                                                in={
                                                  openRowConfiguration[
                                                    row.ProductCode
                                                  ]
                                                }
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                {additionalFields?.map(
                                                  (field, index) => {
                                                    if (
                                                      field.ProductCode ===
                                                      row.ProductCode
                                                    )
                                                      return (
                                                        <Grid
                                                          container
                                                          key={index}
                                                          mt={2}
                                                          style={
                                                            styles.flexAlignCenter
                                                          }
                                                        >
                                                          {apiAdditionalFields?.map(
                                                            (apiField, i) => (
                                                              <Grid
                                                                item
                                                                xs={4}
                                                                key={i}
                                                              >
                                                                <Grid
                                                                  container
                                                                  m={1}
                                                                  style={
                                                                    styles.flexAlignCenter
                                                                  }
                                                                >
                                                                  <Grid
                                                                    item
                                                                    xs={6}
                                                                  >
                                                                    <InputLabel
                                                                      style={
                                                                        styles.label
                                                                      }
                                                                    >
                                                                      {
                                                                        apiField.EntityLabel
                                                                      }
                                                                    </InputLabel>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                    xs={5}
                                                                  >
                                                                    <FormControl
                                                                      fullWidth
                                                                    >
                                                                      <TextField
                                                                        size="small"
                                                                        name={
                                                                          apiField.EntityName
                                                                        }
                                                                        type={
                                                                          apiField.EntityType.toLowerCase() ===
                                                                          "integer"
                                                                            ? "number"
                                                                            : apiField.EntityType.toLowerCase() ===
                                                                              "date"
                                                                            ? "date"
                                                                            : apiField.EntityType.toLowerCase() ===
                                                                              "decimal"
                                                                            ? "number"
                                                                            : apiField.EntityType.toLowerCase() ===
                                                                              "email"
                                                                            ? "email"
                                                                            : "text"
                                                                        }
                                                                        value={getAdditionalTextFieldValue(
                                                                          field,
                                                                          apiField
                                                                        )}
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleInputChange(
                                                                            e,
                                                                            field.ProductCode,
                                                                            apiField.EntityName
                                                                          )
                                                                        }
                                                                        // error={
                                                                        //   apiField.EntityType.toLowerCase() ===
                                                                        //     "email" &&
                                                                        //   field.fields.find(
                                                                        //     (f) =>
                                                                        //       f.EntityName ===
                                                                        //       apiField.EntityName
                                                                        //   )
                                                                        //     ?.error !==
                                                                        //     undefined
                                                                        // }
                                                                        helperText={
                                                                          apiField.EntityType.toLowerCase() ===
                                                                            "email" &&
                                                                          field.fields.find(
                                                                            (
                                                                              f
                                                                            ) =>
                                                                              f.EntityName ===
                                                                              apiField.EntityName
                                                                          )
                                                                            ?.error
                                                                        }
                                                                        sx={{
                                                                          marginLeft: 1,
                                                                          "& .MuiOutlinedInput-root":
                                                                            {
                                                                              borderRadius:
                                                                                "50px",
                                                                            },
                                                                          "& input[type=number]":
                                                                            {
                                                                              // Hide the up and down arrows
                                                                              appearance:
                                                                                "textfield",
                                                                              MozAppearance:
                                                                                "textfield",
                                                                              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                                                                {
                                                                                  display:
                                                                                    "none",
                                                                                  margin: 0,
                                                                                },
                                                                            },
                                                                        }}
                                                                      />
                                                                    </FormControl>
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                            )
                                                          )}
                                                        </Grid>
                                                      );
                                                  }
                                                )}
                                              </Collapse>
                                            </TableCell>
                                          </List>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {
                                <TableRow>
                                  <TableCell
                                    colSpan={6}
                                    align="center"
                                    sx={{ borderBottom: "none" }}
                                  >
                                    No products are selected
                                  </TableCell>
                                </TableRow>
                              }
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={styles.productsHeading}
                    >
                      <b>Current Products</b>
                    </Typography>
                    <CurrentProducts />
                  </Box>

                  <Box mt={3} style={styles.flexBetween}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="error"
                        disabled={submitDisabled}
                        sx={{ borderRadius: "20px" }}
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleSubmitReview}
                      disabled={submitDisabled}
                      sx={{ borderRadius: "20px" }}
                    >
                      Submit
                    </Button>
                    {closePage && (
                      <script>
                        {document.getElementById("cancelBtn").click()}
                      </script>
                    )}
                    <Snackbar
                      open={open}
                      // autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={backgroundColorResponse}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {submittedResponse}
                      </Alert>
                    </Snackbar>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AccountServices;
