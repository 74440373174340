import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { loginActions } from "../actions/loginActions";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { styles } from "./styles";

export const LoginToServices = () => {
  const [loginDetails, setLoginDetails] = useState({
    customerId: "",
    customerType: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const handleServices = () => {
    // console.log(loginDetails);
    // console.log(isLoggedIn);
    dispatch(loginActions.getLoginDetails(loginDetails));
    dispatch(loginActions.getServiceLoginStatus(isLoggedIn));
  };

  useEffect(() => {
    if (loginDetails?.customerId !== "" && loginDetails?.customerType !== "") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [loginDetails]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: "32px" } }}
    >
      <DialogTitle id="alert-dialog-title" style={styles.flexBetweenCenter}>
        {"Enter the below details to proceed"}
        <Button onClick={handleClose} sx={{ color: "red" }}>
          <CancelIcon fontSize="large" />
        </Button>
      </DialogTitle>

      <DialogContent>
        <Box style={styles.dialogContent}>
          <TextField
            type="text"
            name="customerId"
            placeholder="Login ID"
            size="small"
            value={loginDetails.customerId}
            onChange={handleInputChange}
            sx={{ m: 3 }}
          />
          <FormControl size="small" sx={{ m: 3, width: 220 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="customerType"
              value={loginDetails.customerType}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleInputChange}
            >
              <MenuItem value={""}>Select Customer Type</MenuItem>
              <MenuItem value={"Retail"}>Retail</MenuItem>
              <MenuItem value={"Corporate"}>Commercial</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant="contained" onClick={handleServices} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
