export const styles = {
  flexEvenCenter: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  flexEndCenter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  navLinks: {
    textDecoration: "none",
    color: "#fff",
  },
  logInBtn: {
    backgroundColor: "#fff",
    color: "navy",
    fontWeight: "bold",
  },
  logOutBtn: {
    color: "#fff",
    fontWeight: "bold",
  },
  navItemsContainer: {
    marginLeft: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
