import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import atmCard from "../../images/atm2.png";
import ChatBox from "../HomePage/chatBox";
import { styles } from "./styles";

export const HomePage = () => {
  return (
    <Grid container mt={2}>
      <ChatBox />
      <Grid item xs={4} mt={8}>
        <Typography variant="h6" style={styles.flexStart}>
          Free Business Checking Accounts
        </Typography>
        <Typography style={styles.mainHeading}>Business</Typography>
        <Typography style={styles.mainHeading}>checking</Typography>
        <Typography style={styles.mainHeading}>made simpler</Typography>
        <Button variant="contained" size="large" style={styles.getStartedBtn}>
          Get Started
        </Button>
      </Grid>
      <Grid item xs={4} mt={8}>
        <img
          src={atmCard}
          alt="AtmCard"
          width="90%"
          style={styles.atmCardImg}
        />
      </Grid>
    </Grid>
  );
};
