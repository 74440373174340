import { loginActionTypes } from "../actions/actionTypes/loginActionTypes";

let initialState = {
  loginDetails: {
    customerId: "",
    customerType: "",
  },
  loginStatus: false,
  serviceLogStatus: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */

export function loginDetails(state = initialState, action) {
  switch (action.type) {
    case loginActionTypes.LOGIN_DETAILS:
      // console.log(action.loginDetails);
      return {
        ...state,
        loginDetails: action.loginDetails,
      };

    case loginActionTypes.IS_SERVICE_LOGIN:
      return { ...state, serviceLogStatus: action.serviceLogStatus };

    case loginActionTypes.ISLOGGEDIN:
      // console.log(action.loginStatus);
      return { ...state, loginStatus: action.loginStatus };

    default:
      return state;
  }
}
