/**
 * Root Reducer
 */
import { combineReducers } from "redux";
import { loginDetails } from "./loginReducer";
import { serviceDetails } from "./serviceReducer";

export default combineReducers({
  loginDetails,
  serviceDetails,
});
