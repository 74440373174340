import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styles } from "./styles";
import { useSelector } from "react-redux";

const CurrentProducts = () => {
  const [currentProducts, setCurrentProducts] = useState([]);
  const [openCurrentRows, setOpenCurrentRows] = useState({});
  const [openRowOverview, setOpenRowOverview] = useState({});
  const [openConfigurations, setOpenConfigurations] = useState({});
  const [apiAdditionalFields, setApiAdditionalFields] = useState({});

  const customerId =
    useSelector((state) => state?.loginDetails?.loginDetails.customerId) || "";

  useEffect(() => {
    async function fetchCurrentProducts() {
      const response = await fetch(
        `https://cpao.evonsys.com.au/prweb/api/v1/data/D_CustomerProdtDetails?CustomerID=${customerId}`
      );

      const curPdtsJsonData = await response.json();
      const currentProducts = curPdtsJsonData.pxResults;
      setCurrentProducts(currentProducts);
    }
    fetchCurrentProducts();
  }, [customerId]);

  const fetchAdditionalFields = async (productId) => {
    const response = await fetch(
      `https://cpao.evonsys.com.au/prweb/api/v1/data/D_FetchAdditionalFields?CustomerID=${customerId}&ProductID=${productId}`
    );
    const jsonData = await response.json();
    const additionalData = jsonData.pxResults;
    console.log(additionalData);
    setApiAdditionalFields((prevState) => ({
      ...prevState,
      [productId]: additionalData,
    }));
  };

  const toggleCurrentRowOpenState = (productCode) => {
    setOpenCurrentRows((prevState) => ({
      ...prevState,
      [productCode]: !prevState[productCode],
    }));
  };

  const toggleOverview = (productCode) => {
    setOpenRowOverview((prevState) => ({
      ...prevState,
      [productCode]: !prevState[productCode],
    }));
  };

  const toggleConfigurations = (productCode) => {
    if (!openConfigurations[productCode]) {
      fetchAdditionalFields(productCode);
    }
    setOpenConfigurations((prevState) => ({
      ...prevState,
      [productCode]: !prevState[productCode],
    }));
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 1200 }} size="small" aria-label="simple table">
        <TableHead style={styles.tableHeading}>
          <TableRow>
            <TableCell style={styles.tableHeadingLeftCells}></TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <b>Jurisdiction</b>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <b>Product Category</b>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <b>Product Name</b>
            </TableCell>
            <TableCell style={styles.tableHeadingRightCells}>
              <b>Product Type</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentProducts.map((row) => (
            <React.Fragment key={row.ProductCode}>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  onClick={() => toggleCurrentRowOpenState(row.ProductCode)}
                  sx={{ cursor: "pointer", border: "none" }}
                >
                  {openCurrentRows[row.ProductCode] ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {row.EntityValue1}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {row.ProductCategory}
                </TableCell>
                <TableCell sx={{ border: "none" }}>{row.ProductName}</TableCell>
                <TableCell sx={{ border: "none" }}>{row.ProductType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.expandedRow} colSpan={6}>
                  <Collapse
                    in={openCurrentRows[row.ProductCode]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={1}>
                      <List
                        component="div"
                        disablePadding
                        sx={{ marginLeft: 8 }}
                      >
                        <ListItemButton
                          style={styles.expandedListBtn}
                          onClick={() => toggleOverview(row.ProductCode)}
                        >
                          {openRowOverview[row.ProductCode] ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowRightIcon />
                          )}
                          <ListItemText primary="Overview" />
                        </ListItemButton>
                        <Collapse
                          in={openRowOverview[row.ProductCode]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box style={styles.expandedRow} marginLeft={8}>
                            {row.ProductDescription}
                          </Box>
                        </Collapse>
                        <ListItemButton style={styles.expandedListBtn}>
                          <ArrowRightIcon />
                          <ListItemText primary="Credit" />
                        </ListItemButton>
                        <ListItemButton style={styles.expandedListBtn}>
                          <ArrowRightIcon />
                          <ListItemText primary="Documentation" />
                        </ListItemButton>
                        <ListItemButton style={styles.expandedListBtn}>
                          <ArrowRightIcon />
                          <ListItemText primary="Other" />
                        </ListItemButton>
                        <ListItemButton
                          style={styles.expandedListBtn}
                          onClick={() => toggleConfigurations(row.ProductCode)}
                        >
                          {openConfigurations[row.ProductCode] ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowRightIcon />
                          )}
                          <ListItemText primary="Product Configurations" />
                        </ListItemButton>
                        <Collapse
                          in={openConfigurations[row.ProductCode]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box style={styles.expandedRow} marginLeft={8}>
                            <Grid
                              container
                              m={1}
                              style={styles.flexAlignCenter}
                            >
                              {apiAdditionalFields[row.ProductCode]?.map(
                                (field, index) => (
                                  <Grid item xs={4} key={index}>
                                    <Grid
                                      container
                                      m={1}
                                      style={styles.flexAlignCenter}
                                    >
                                      <Grid item xs={5}>
                                        <InputLabel>
                                          {field.EntityLabel}
                                        </InputLabel>
                                      </Grid>
                                      <Grid item xs={5}>
                                        {
                                          <FormControl fullWidth>
                                            <TextField
                                              size="small"
                                              name={field.EntityName}
                                              type="text"
                                              value={
                                                field?.EntityIntegerValue ||
                                                field.EntityEmailValue ||
                                                field.EntityDateValue ||
                                                field.EntityDecimalValue ||
                                                field.EntityTextValue ||
                                                "--"
                                              }
                                              disabled
                                              sx={{
                                                marginLeft: 1,
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                              }}
                                            />
                                          </FormControl>
                                        }
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Box>
                        </Collapse>
                      </List>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CurrentProducts;
