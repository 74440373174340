import { serviceActionTypes } from "../actions/actionTypes/serviceActionTypes";

let initialState = {
  selectedRows: [],
  updatedRows: [],
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */

export function serviceDetails(state = initialState, action) {
  switch (action.type) {
    case serviceActionTypes.SELECTED_ROWS:
      return { ...state, selectedRows: action.selectedRows };

    case serviceActionTypes.UPDATED_ROWS:
      return {
        ...state,
        updatedRows: action.updatedRows,
      };

    default:
      return state;
  }
}
