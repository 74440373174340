export const styles = {
  flexAround: {
    display: "flex",
    justifyContent: "space-around",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexBetweenCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  servicesContainer: {
    padding: "16px",
    width: "100%",
    height: "550px",
    overflowY: "scroll",
    borderRadius: "30px",
  },

  label: {
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  headingContainer: {
    backgroundColor: "#d5de9b",
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: "30px",
  },
  avatar: {
    backgroundColor: "#9933aa",
    marginRight: "8px",
    width: 30,
    height: 30,
    fontSize: "15px",
  },
  productsHeading: {
    color: "#9933aa",
  },
  tableHeading: {
    backgroundColor: "#d8ddf8",
  },
  tableHeadingLeftCells: {
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    border: "none",
  },
  tableHeadingRightCells: {
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    border: "none",
  },
  expandedRow: {
    paddingBottom: 0,
    paddingTop: 0,
    border: "none",
  },
  expandedListBtn: {
    backgroundColor: "#e6f9f9",
    margin: "8px",
    borderRadius: "25px",
  },
};
