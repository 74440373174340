export const styles = {
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  flexBetweenCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loginBtn: {
    marginTop: 25,
    backgroundColor: "#fff",
    color: "navy",
    fontWeight: "bold",
  },
  links: {
    color: "#02c6a6",
    fontSize: "20px",
    margin: 20,
  },
  dialogContent: {
    width: "80vw",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
