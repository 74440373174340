import "./App.css";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import { LoginPage } from "./components/LoginPage/LoginPage";
import { HomePage } from "./components/HomePage/homePage";
import AccountServices from "./components/Services/AccountServices/accountServices";
import TreasuryServices from "./components/Services/TreasuryServices/treasuryServices";
import { configureStore } from "./components/store/index";
import { Provider } from "react-redux";

const store = configureStore();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/loginPage" element={<LoginPage />} />
          <Route path="/accountServices" element={<AccountServices />} />
          <Route path="/treasuryServices" element={<TreasuryServices />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
