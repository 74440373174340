import { loginActionTypes } from "./actionTypes/loginActionTypes";

export const loginActions = {
  getLoginDetails,
  getLoginStatus,
  getServiceLoginStatus,
};

function getLoginDetails(loginDetails) {
  return { type: loginActionTypes.LOGIN_DETAILS, loginDetails: loginDetails };
}

function getLoginStatus(status) {
  // console.log(status);
  return { type: loginActionTypes.ISLOGGEDIN, loginStatus: status };
}

function getServiceLoginStatus(serviceLogStatus) {
  return {
    type: loginActionTypes.IS_SERVICE_LOGIN,
    serviceLogStatus: serviceLogStatus,
  };
}
