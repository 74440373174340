export const styles = {
  /*ChatBox styles */
  chatBoxImg: {
    display: "flex",
    justifyContent: "flex-start",
  },

  /*HomePage styles */
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  mainHeading: {
    fontWeight: "bolder",
    fontSize: "75px",
    display: "flex",
    justifyContent: "flex-start",
  },
  getStartedBtn: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    color: "navy",
    fontWeight: "bold",
  },
  atmCardImg: {
    transform: "rotate(38deg) translateX(180px)",
  },
};
