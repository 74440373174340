import React, { useState } from "react";
import {
  Button,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { styles } from "./styles";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorTreasuryEl, setAnchorTreasuryEl] = useState(null);
  const openTreasury = Boolean(anchorTreasuryEl);
  const [ishome, setIsHome] = useState(true);

  let isLoggedIn =
    useSelector((state) => state?.loginDetails?.loginStatus) || false;

  const loginDetails =
    useSelector((state) => state?.loginDetails?.loginDetails) || {};

  const isServiceLogin =
    useSelector((state) => state?.loginDetails?.serviceLogStatus) || false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTreasury = (event) => {
    setAnchorTreasuryEl(event.currentTarget);
  };

  const handleCloseTreasury = () => {
    setAnchorTreasuryEl(null);
  };

  const handleAuthorization = async () => {
    setAnchorEl(null);
    const response = await fetch(
      "https://cpao.evonsys.com.au/prweb/PRRestService/oauth2/v1/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: new URLSearchParams({
          client_id: "11719855563824116817",
          client_secret: "238BB45604C1D1B594C4705D1487D970",
          grant_type: "client_credentials",
        }),
      }
    );
    const jsonData = await response.json();

    if (response.status === 200) {
      const res = await fetch(
        "https://cpao.evonsys.com.au/prweb/api/application/v2/cases",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jsonData.access_token,
          },
          body: JSON.stringify({
            caseTypeID: "ES-CPAO-Work-NewProduct",
          }),
        }
      );
      const data = await res.json();
      const responseData = JSON.stringify(data);
      alert(responseData);
    } else {
      alert("API call failed");
    }
  };

  const handleLoginPage = () => {
    setIsHome(false);
  };

  return (
    <div>
      <Grid container mt={1}>
        <Grid item xs={1} sx={{ cursor: "pointer" }}>
          <Link to="/" style={styles.navLinks}>
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              T3
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              &nbsp;&nbsp;&nbsp;bank
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={11}>
          <Grid container justifyContent="space-between">
            <Grid item xs={8} style={styles.flexEvenCenter}>
              <Typography variant="subtitle">Personal</Typography>
              <Typography variant="subtitle">Business</Typography>
              <Typography variant="subtitle">Fintech</Typography>
              <Typography variant="subtitle">Talk to an Agent</Typography>
              <Typography variant="subtitle">About Us</Typography>
              <Typography variant="subtitle">News</Typography>
            </Grid>
            <Grid item xs={3} mr={3} style={styles.flexEndCenter}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  padding: 1,
                }}
              >
                {isLoggedIn || isServiceLogin
                  ? loginDetails?.customerId + "  " + loginDetails?.customerType
                  : ""}
              </Typography>
              {isLoggedIn || isServiceLogin ? (
                <Button
                  size="large"
                  style={styles.logOutBtn}
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  <PowerSettingsNewIcon />
                </Button>
              ) : (
                <Link to="/loginPage" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    size="large"
                    style={styles.logInBtn}
                    onClick={handleLoginPage}
                  >
                    Login
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid item xs={9} style={styles.navItemsContainer}>
              <Typography variant="h6">Profile Management</Typography>
              <Typography
                variant="h6"
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
              >
                Account Services
              </Typography>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Paper sx={{ width: 230, maxWidth: "100%" }}>
                  <MenuList>
                    <MenuItem onClick={handleAuthorization}>
                      <ListItemText>Add an Authorized Signer</ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemText>Account Name Change</ListItemText>
                    </MenuItem>
                    <Link
                      to="/accountServices"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem
                        onClick={() => {
                          setIsHome(false);
                          setAnchorEl(null);
                        }}
                      >
                        <ListItemText>Open a New Account</ListItemText>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Paper>
              </Popover>

              <Typography variant="h6">Business Credit Cards</Typography>
              <Typography variant="h6">Commercial Loans</Typography>
              <Typography
                variant="h6"
                onClick={handleClickTreasury}
                sx={{ cursor: "pointer" }}
              >
                Treasury Management
              </Typography>
              <Popover
                open={openTreasury}
                anchorEl={anchorTreasuryEl}
                onClose={handleCloseTreasury}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Paper sx={{ width: 230, maxWidth: "100%" }}>
                  <MenuList>
                    <Link
                      to="/treasuryServices"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem
                        onClick={() => {
                          setIsHome(false);
                          setAnchorTreasuryEl(null);
                        }}
                      >
                        <ListItemText>Add a Treasury Product</ListItemText>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Paper>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {ishome ? <Navigate to="/" /> : ""}
    </div>
  );
};

export default NavBar;
